import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as selectors from '../selectors/selectors';

function FormQuestionLabel({ id, text, formSlug }) {
  return (
    <label
      id={`q${id}`}
      className={`question-label question-label--${formSlug}`}
      htmlFor={id}
    >
      {text}
      <span className="question-mark">?</span>
    </label>
  );
}

FormQuestionLabel.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  formSlug: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  id: selectors.currentQuestionIndex(state),
  text: selectors.currentQuestion(state).text,
  formSlug: selectors.formSlug(state),
});

export default connect(mapStateToProps)(FormQuestionLabel);
