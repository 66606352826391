import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import { scrollTo } from '../helpers/helperFunctions';
import * as formActions from '../actions/formActions';
import * as selectors from '../selectors/selectors';
import * as globals from '../constants/globals';

function HeroCta({
  text,
  dispatch,
  loginMethods,
  router,
  router: { pathname },
}) {
  const componentClasses = ['hero__cta'];
  return (
    <button
      type="button"
      tabIndex={0}
      id="cta"
      onKeyPress={() => {}}
      onClick={() => {
        if (
          // If this is a password creation scenario where the user has already
          // created a password then the click should take to login page.
          pathname === globals.PATHNAME_CREATE_PASSWORD &&
          loginMethods.includes(globals.LOGIN_METHOD_USERNAME_PASSWORD)
        ) {
          router.push(globals.PATHNAME_LOGIN);
          return;
        }
        const toScroll = document
          .querySelector('.hero__image')
          .getBoundingClientRect().top;
        dispatch(formActions.setFormOpen(true));
        dispatch(formActions.setFormExited(false));
        scrollTo(document.body, toScroll, 800);
      }}
      className={componentClasses.join(' ')}
    >
      {text}
    </button>
  );
}

HeroCta.propTypes = {
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.instanceOf(Object).isRequired,
  loginMethods: PropTypes.instanceOf(Array).isRequired,
  text: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  loginMethods: selectors.loginMethods(state),
});

export default withRouter(connect(mapStateToProps)(HeroCta));
