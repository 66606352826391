import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import FormQuestion from './FormQuestion';
import FormSubmitResult from './FormSubmitResult';
import FormControls from './FormControls';
import FormQuestionLabel from './FormQuestionLabel';
import ForgotPassword from './ForgotPassword';
import * as globals from '../constants/globals';
import * as selectors from '../selectors/selectors';
import * as formActions from '../actions/formActions';

function Form({
  currentQuestion,
  dispatch,
  formSubmitResult,
  formType,
  questionAnswered,
}) {
  return formSubmitResult ? (
    <FormSubmitResult />
  ) : (
    <form
      className={classNames('wpg-form', {
        'question-answered': questionAnswered,
      })}
      onSubmit={(event) => {
        event.preventDefault();
        dispatch(formActions.submitForm());
      }}
    >
      <div className={classNames('wpg-form__inner', { hide: false })}>
        <div className={`questions ${currentQuestion.tag}`}>
          <div className="item">
            <TransitionGroup className="question-label-cont">
              <CSSTransition
                classNames="question-label"
                timeout={400}
                key={currentQuestion.id}
              >
                <FormQuestionLabel />
              </CSSTransition>
            </TransitionGroup>
            <FormQuestion />
          </div>
        </div>
        <FormControls />
        {formType === globals.PATHNAME_LOGIN && <ForgotPassword />}
      </div>
    </form>
  );
}

Form.propTypes = {
  currentQuestion: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  questionAnswered: PropTypes.bool.isRequired,
  formSubmitResult: PropTypes.string.isRequired,
  formType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  currentQuestion: selectors.currentQuestion(state),
  questionAnswered: selectors.questionAnswered(state),
  formSubmitResult: selectors.formSubmitResult(state),
  formType: selectors.formType(state),
});

export default connect(mapStateToProps)(Form);
