import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as formActions from '../actions/formActions';
import * as selectors from '../selectors/selectors';

class FormQuestion extends Component {
  constructor(props) {
    super(props);
    this.myRef = createRef();
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.myRef.current.focus();
    dispatch(formActions.setQuestionFocus(true));
  }

  render() {
    const {
      answer,
      autocomplete,
      dispatch,
      formSlug,
      id,
      maxlength,
      placeholder,
      slug,
      tag,
      type,
    } = this.props;
    const Tag = `${tag}`;
    if (Tag === 'select') {
      return (
        <Tag
          autoComplete={autocomplete}
          ref={this.myRef}
          className={formSlug}
          onFocus={() => dispatch(formActions.setQuestionFocus(true))}
          onBlur={() => dispatch(formActions.setQuestionFocus(false))}
          onChange={(e) =>
            dispatch(formActions.setAnswer(slug, e.target.value))
          }
        >
          {placeholder.map((option) => (
            <option key={option} id={option[1]} name={slug} value={option[1]}>
              {option[0]}
            </option>
          ))}
        </Tag>
      );
    }
    if (Tag !== 'fieldset') {
      // Input
      return (
        <Tag
          autoComplete={autocomplete}
          className={formSlug}
          id={id}
          maxLength={maxlength}
          name={id}
          onBlur={() => dispatch(formActions.setQuestionFocus(false))}
          onChange={(e) =>
            dispatch(formActions.setAnswer(slug, e.target.value))
          }
          onFocus={() => dispatch(formActions.setQuestionFocus(true))}
          placeholder={placeholder}
          ref={this.myRef}
          type={type}
          value={answer}
        />
      );
    }
    // Fieldset
    return (
      <Tag
        className={`${formSlug} questions-${placeholder.length}`}
        onBlur={() => dispatch(formActions.setQuestionFocus(false))}
        onFocus={() => dispatch(formActions.setQuestionFocus(true))}
        ref={this.myRef}
      >
        {placeholder.map((button) => (
          <div key={button}>
            <input
              autoComplete={autocomplete}
              onClick={() => {
                let checkData;
                if (type === 'checkbox') {
                  const checks = document.querySelectorAll(
                    `input[type=${type}]:checked`
                  );
                  checkData = [...checks].map((check) => check.id);
                } else {
                  const check = document.querySelector(
                    `input[type=${type}]:checked`
                  );
                  checkData = check.id;
                }
                dispatch(formActions.setAnswer(slug, checkData));
                dispatch(formActions.setQuestionFocus(true));
              }}
              id={button[1]}
              type={type}
              name={slug}
              value={button[1]}
            />
            <label htmlFor={button[1]} className={`${type}-label`}>
              {button[0]}
            </label>
            <br />
          </div>
        ))}
      </Tag>
    );
  }
}

FormQuestion.defaultProps = {
  autocomplete: undefined,
  maxlength: undefined,
  formSlug: '',
  placeholder: '',
};

FormQuestion.propTypes = {
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  autocomplete: PropTypes.oneOfType([PropTypes.string, () => undefined]),
  dispatch: PropTypes.func.isRequired,
  formSlug: PropTypes.string,
  id: PropTypes.number.isRequired,
  maxlength: PropTypes.oneOfType([() => undefined, PropTypes.string]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  slug: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  answer: selectors.currentAnswer(state),
  autocomplete: selectors.currentQuestion(state).autocomplete,
  formSlug: selectors.formSlug(state),
  id: selectors.currentQuestionIndex(state),
  maxlength: selectors.currentQuestion(state).maxlength,
  placeholder: selectors.currentQuestion(state).placeholder,
  slug: selectors.currentQuestion(state).slug,
  tag: selectors.currentQuestion(state).tag,
  type: selectors.currentQuestion(state).type,
});

export default connect(mapStateToProps)(FormQuestion);
