import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import * as selectors from '../selectors/selectors';

function FormControls({
  currentProgress,
  currentQuestionIndex,
  error,
  formSlug,
  questionIsFocused,
  questions,
}) {
  return (
    <div className="controls">
      <CSSTransition
        in={questionIsFocused}
        mountOnEnter
        unmountOnExit
        classNames="basic"
        timeout={300}
      >
        <button
          aria-label="Next"
          type="submit"
          key="nextButton"
          className={`next show next--${formSlug}`}
        />
      </CSSTransition>
      <div className={`progress-bg progress-bg--${formSlug}`} />
      <div
        className={`progress progress--${formSlug}`}
        style={{
          width: `${currentProgress}%`,
        }}
      />
      <span className={`number number--${formSlug}`}>
        <CSSTransition component="div" classNames="number" timeout={400}>
          <span className="number-current">{currentQuestionIndex + 1}</span>
        </CSSTransition>
        <span className="number-total">{questions.length}</span>
      </span>
      <CSSTransition in={!!error} classNames="default-fade" timeout={400}>
        <span
          key={error}
          className={`error-message error-message--${formSlug}`}
        >
          {error}
        </span>
      </CSSTransition>
    </div>
  );
}

FormControls.propTypes = {
  currentProgress: PropTypes.number.isRequired,
  currentQuestionIndex: PropTypes.number.isRequired,
  error: PropTypes.string.isRequired,
  formSlug: PropTypes.string.isRequired,
  questionIsFocused: PropTypes.bool.isRequired,
  questions: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = (state) => ({
  currentProgress: selectors.currentProgress(state),
  currentQuestionIndex: selectors.currentQuestionIndex(state),
  error: selectors.formError(state),
  formSlug: selectors.formSlug(state),
  questionIsFocused: selectors.questionFocus(state),
  questions: selectors.questions(state),
});

export default connect(mapStateToProps)(FormControls);
