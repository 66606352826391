import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as globals from '../constants/globals';
import * as selectors from '../selectors/selectors';

function FormSubmitResult({ formSubmitResult, formType, formSlug }) {
  if (
    formType === globals.PATHNAME_HOME ||
    formType === globals.PATHNAME_JOIN
  ) {
    return (
      <div className={`form-result form-result--${formSlug}`}>
        <h1 className="form-result__heading">
          {formSubmitResult === globals.FORM_SUBMIT_SUCCESS
            ? 'Kiitos!'
            : 'Tapahtui virhe'}
        </h1>
        <p className="form-result__info">
          {formSubmitResult === globals.FORM_SUBMIT_SUCCESS
            ? 'Klikkaa vielä vahvistuslinkkiä sähköpostissasi.'
            : 'Ylläpitoa on tiedotettu. Koita pian uudelleen.'}
        </p>
      </div>
    );
  }

  if (formType === globals.PATHNAME_CREATE_PASSWORD) {
    return (
      <div className={`form-result form-result--${formSlug}`}>
        <h1 className="form-result__heading">
          {formSubmitResult === globals.FORM_SUBMIT_SUCCESS
            ? 'Salasana luotu'
            : 'Tapahtui virhe'}
        </h1>
        <p className="form-result__info">
          {formSubmitResult === globals.FORM_SUBMIT_SUCCESS
            ? 'Voit kirjautua palveluun sähköpostiosoitteellasi ja salasanallasi.'
            : 'Ylläpitoa on tiedotettu. Koita pian uudelleen.'}
        </p>
      </div>
    );
  }

  if (formType === globals.PATHNAME_FORGOT) {
    return (
      <div className={`form-result form-result--${formSlug}`}>
        <h1 className="form-result__heading">
          {formSubmitResult === globals.FORM_SUBMIT_SUCCESS
            ? 'Ei huolta'
            : 'Tapahtui virhe'}
        </h1>
        <p className="form-result__info">
          {formSubmitResult === globals.FORM_SUBMIT_SUCCESS
            ? `Ohjeet salasanan palauttamiseksi on lähetetty sähköpostiisi.`
            : 'Ylläpitoa on tiedotettu. Koita pian uudelleen.'}
        </p>
      </div>
    );
  }

  return <h1 className="form-result__heading">Tapahtui virhe</h1>;
}

FormSubmitResult.propTypes = {
  formSubmitResult: PropTypes.string.isRequired,
  formType: PropTypes.string.isRequired,
  formSlug: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  formSubmitResult: selectors.formSubmitResult(state),
  formType: selectors.formType(state),
  formSlug: selectors.formSlug(state),
});

export default connect(mapStateToProps)(FormSubmitResult);
