import React from 'react';
import Link from 'next/link';
import * as globals from '../constants/globals';

function ForgotPassword() {
  return (
    <Link href={globals.VIEW_SLUG_FORGOT} as="/unohdin-salasanan">
      <p className="login__forgot">Unohdin salasanani</p>
    </Link>
  );
}

export default ForgotPassword;
