import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Form from './Form';
import HeroCta from './HeroCta';
import * as formActions from '../actions/formActions';
import * as selectors from '../selectors/selectors';
import * as globals from '../constants/globals';

function Hero(props) {
  const {
    dispatch,
    heading,
    intro,
    ctaText,
    heroType,
    formOpen,
    formExited,
    formReadyToOpen,
  } = props;
  return (
    <section className={`hero hero--${heroType}`}>
      <div
        className={classNames(`hero__image`, {
          'hero__image--form-open': formOpen,
        })}
      >
        <CSSTransition
          classNames="fade-in-out-500"
          in={!formOpen && formExited}
          onExited={() => dispatch(formActions.setFormReadyToOpen(true))}
          timeout={500}
          mountOnEnter
          unmountOnExit
        >
          <div className="hero__content">
            <div className="hero__top">
              <div className="hero__info-container">
                <div className="hero__info-content">
                  <h2 className="hero__heading">{heading}</h2>
                  <p
                    className="hero__intro"
                    dangerouslySetInnerHTML={{ __html: intro }}
                  />
                  <div className="hero__buttons">
                    <HeroCta text={ctaText} />
                    {(heroType === globals.HEROTYPE_HOME ||
                      heroType === globals.HEROTYPE_SHOOTER) && (
                      <button
                        type="button"
                        className="hero__more"
                        onClick={() => {
                          const info = document.getElementById('info');
                          info && info.scrollIntoView(); // eslint-disable-line
                        }}
                      >
                        Tutustu palveluun
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
        <CSSTransition
          classNames="fade-in-out-500"
          in={!formOpen && formExited}
          timeout={500}
          mountOnEnter
          unmountOnExit
        >
          <div className="hero__deco" />
        </CSSTransition>
        <CSSTransition
          classNames="fade-in-out-500"
          in={formReadyToOpen && formOpen}
          mountOnEnter
          unmountOnExit
          timeout={500}
          onExited={() => dispatch(formActions.setFormExited(true))}
        >
          <Form />
        </CSSTransition>
      </div>
    </section>
  );
}

Hero.defaultProps = {
  formReadyToOpen: undefined,
  formExited: undefined,
  formOpen: false,
};

Hero.propTypes = {
  ctaText: PropTypes.string.isRequired,
  formOpen: PropTypes.bool,
  formExited: PropTypes.oneOfType([() => undefined, PropTypes.bool]),
  formReadyToOpen: PropTypes.oneOfType([() => undefined, PropTypes.bool]),
  heading: PropTypes.string.isRequired,
  heroType: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  formOpen: selectors.formOpen(state),
  formExited: selectors.formExited(state),
  formReadyToOpen: selectors.formReadyToOpen(state),
});

export default connect(mapStateToProps)(Hero);
